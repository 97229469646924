import classNames from "classnames";
import { ChangeEvent } from "react";
import { HexColorPicker } from "react-colorful";
import {
  Controller,
  ControllerFieldState,
  ControllerProps,
  ControllerRenderProps,
  FieldError,
  FieldPath,
  useFormContext,
  UseFormStateReturn,
} from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types";
import { FormattedMessage, useIntl } from "react-intl";

import { Checkbox } from "../checkbox";
import { Input, InputProps } from "../input";
import { Select, SelectProps } from "../select";
import Tooltip from "../tooltip/Tooltip";
import { DataAttributes, ValidTailwindSizes } from "../types";
import { convertDataAttributes } from "../utils";
import { hexToRgb, rgbToHex } from "../utils/color";
import { DatePicker, DatePickerProps, TextArea, TextAreaProps } from ".";

interface RHFInputProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  inputProps:
    | {
        placeholder: InputProps["placeholder"];
        onBlur: InputProps["onBlur"];
      }
    | InputProps;
  onChangeAction?: (event: ChangeEvent<HTMLInputElement>) => void;
  autoPassError?: boolean;
}

const getErrorProps = ({
  autoPassError,
  error,
}: {
  autoPassError?: boolean;
  error?: FieldError | undefined;
}) => {
  if (!autoPassError) {
    return {};
  }

  if (!error) {
    return {};
  }

  return {
    errorMessage: error.message,
    state: "ERROR" as const,
    iconVariant: "ALERT" as const,
  };
};

export function RHFInputField({
  name,
  controllerProps,
  inputProps,
  onChangeAction,
  autoPassError = true,
}: RHFInputProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
        <Input
          value={value}
          onChange={(event) => {
            onChange(event);
            onChangeAction && onChangeAction(event);
          }}
          {...inputProps}
          {...getErrorProps({ autoPassError, error })}
          onBlur={(e) => {
            onBlur();
            inputProps.onBlur?.(e);
          }}
        />
      )}
      {...controllerProps}
    />
  );
}

interface RenderProps<T extends FieldValues> {
  field: ControllerRenderProps<T, FieldPath<T>>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<T>;
}

interface RHFSelectProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  selectProps?: Omit<SelectProps, "value" | "onChange">;
  getRenderProps?: (
    renderProps: RenderProps<any>
  ) => Partial<Omit<SelectProps, "value" | "onChange">>;
  // TODO:
  //   Escape hatch for back compat, need to check all `RHFSelectField`
  //   callsites and make `autoPassError` default
  autoPassError?: boolean;
  disabled?: boolean;
}

export function RHFSelectField({
  name,
  controllerProps,
  selectProps,
  getRenderProps,
  autoPassError,
  disabled,
}: RHFSelectProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={(renderProps) => {
        const {
          field: { value, onChange },
          fieldState: { error },
        } = renderProps;
        return (
          <Select
            {...selectProps}
            disabled={disabled}
            value={value}
            onChange={onChange}
            {...getRenderProps?.(renderProps)}
            {...(autoPassError
              ? { error: Boolean(error?.message), errorMessage: error?.message }
              : {})}
          />
        );
      }}
      {...controllerProps}
    />
  );
}

interface RHFDateProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  datePickerProps?: Omit<DatePickerProps, "onDayClick" | "input">;
  autoPassError?: boolean;
}

export function RHFDateField({
  name,
  controllerProps,
  datePickerProps,
  autoPassError = true,
}: RHFDateProps) {
  const { control } = useFormContext();
  const { inputProps, ...otherDatePickerProps } = datePickerProps || {};

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <DatePicker
          initialMonth={new Date()}
          input={true}
          onDayClick={({ day }) => onChange(day)}
          inputProps={{
            ...inputProps,
            ...getErrorProps({ autoPassError, error }),
          }}
          {...otherDatePickerProps}
        />
      )}
      {...controllerProps}
    />
  );
}

interface RHFTextAreaProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  textAreaProps:
    | {
        placeholder: TextAreaProps["placeholder"];
      }
    | TextAreaProps;
  onChangeAction?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function RHFTextAreaField({
  name,
  controllerProps,
  textAreaProps,
  onChangeAction,
}: RHFTextAreaProps) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <TextArea
          value={value}
          onChange={(event) => {
            onChange(event);
            onChangeAction && onChangeAction(event);
          }}
          {...textAreaProps}
          onBlur={onBlur}
        />
      )}
      {...controllerProps}
    />
  );
}

interface RHFCheckboxProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  checkboxProps?: Omit<InputProps, "value" | "onChange" | "type">;
  autoPassError?: boolean;
}

export function RHFCheckboxField({
  name,
  controllerProps,
  checkboxProps,
  autoPassError = true,
}: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Checkbox
          checked={Boolean(value)}
          onChange={(checked) => {
            onChange(checked);
          }}
          {...checkboxProps}
          {...getErrorProps({ autoPassError, error })}
        />
      )}
      {...controllerProps}
    />
  );
}

interface RHFColorPickerProps {
  name: string;
  controllerProps?: Partial<ControllerProps>;
  width?: `w-${ValidTailwindSizes}`;
  placeholder?: string;
  dataAttributes?: DataAttributes;
  autoPassError?: boolean;
}

export function RHFColorPickerField({
  name,
  controllerProps,
  width = "w-full",
  placeholder,
  dataAttributes = {},
}: RHFColorPickerProps) {
  const { control } = useFormContext();
  const intl = useIntl();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        const dataAttr = convertDataAttributes(dataAttributes);
        const rgbColor = hexToRgb(value);

        const updateColor = (newColor: string) => {
          onChange?.(newColor);
        };

        const containsNonHexChars = (val: string) => /[^a-f0-9]/gi.test(val);

        return (
          <Tooltip
            position="top"
            trigger="onClick"
            offset={[0, 12]}
            widthClassName="w-auto"
            body={
              <div className="w-80 p-1">
                <HexColorPicker
                  color={value || "000000"}
                  onChange={(val) => updateColor(val.replace("#", ""))}
                />
                <div className="flex pt-3 font-medium">
                  <div className="flex-1 pr-1.5">
                    <label className="inline-block pb-1 pl-1">
                      <FormattedMessage defaultMessage="Hex" id="1bFy3d" />
                    </label>
                    <Input
                      className="bg-white"
                      dataAttributes={{ testid: "colorpicker-hex-input" }}
                      value={`#${value}`}
                      onChange={(event) => {
                        const formattedVal = event.target.value.replace("#", "").substring(0, 6);

                        if (!containsNonHexChars(formattedVal) || !formattedVal) {
                          updateColor(formattedVal);
                        }
                      }}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Hex",
                        id: "1bFy3d",
                      })}
                      fluid
                    />
                  </div>
                  <div className="w-16 pr-1.5">
                    {/* eslint-disable-next-line @nx/workspace/no-raw-value */}
                    <label className="inline-block pb-1 pl-1">R</label>
                    <Input
                      type="number"
                      className="bg-white !pr-0"
                      dataAttributes={{ testid: "colorpicker-red-input" }}
                      value={`${rgbColor[0]}`}
                      onChange={(event) => {
                        updateColor(rgbToHex(event.target.value, rgbColor[0], rgbColor[2]));
                      }}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Red",
                        id: "D+ZFS/",
                      })}
                      fluid
                    />
                  </div>
                  <div className="w-16 pr-1.5">
                    {/* eslint-disable-next-line @nx/workspace/no-raw-value */}
                    <label className="inline-block pb-1 pl-1">G</label>
                    <Input
                      type="number"
                      className="bg-white !pr-0"
                      dataAttributes={{ testid: "colorpicker-green-input" }}
                      value={`${rgbColor[1]}`}
                      onChange={(event) => {
                        updateColor(rgbToHex(rgbColor[0], event.target.value, rgbColor[2]));
                      }}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Green",
                        id: "1W4okO",
                      })}
                      fluid
                    />
                  </div>
                  <div className="w-16">
                    {/* eslint-disable-next-line @nx/workspace/no-raw-value */}
                    <label className="inline-block pb-1 pl-1">B</label>
                    <Input
                      type="number"
                      className="bg-white !pr-0"
                      dataAttributes={{ testid: "colorpicker-blue-input" }}
                      value={`${rgbColor[2]}`}
                      onChange={(event) => {
                        updateColor(rgbToHex(rgbColor[0], rgbColor[1], event.target.value));
                      }}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Blue",
                        id: "k2tJ9k",
                      })}
                      fluid
                    />
                  </div>
                </div>
              </div>
            }
          >
            <div
              {...dataAttr}
              className={classNames(
                "flex h-10 cursor-pointer items-center rounded border border-blueGray200 px-4 text-sm text-blueGray600",
                width
              )}
            >
              <span
                className="border-blueGray50 mr-2 h-6 w-6 rounded-full border"
                style={{ backgroundColor: `#${value || "fff"}` }}
              />
              <span className="mt-0.5">
                {value || placeholder || (
                  <FormattedMessage defaultMessage="Choose a color" id="w6zCgp" />
                )}
              </span>
            </div>
          </Tooltip>
        );
      }}
      {...controllerProps}
    />
  );
}
