/**
 * Example: hexToRgb("ff0008") -> [ "255", "0", "8" ]
 */
export const hexToRgb = (hex = "#000000") => {
  const formatted = hex
    ?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => r + r + g + g + b + b)
    ?.match(/.{2}/g);

  if (!formatted || formatted.length < 3) return ["0", "0", "0"];

  return formatted.map((x) => parseInt(x, 16));
};

/**
 * Example: rgbToHex(255, 0, 8) -> "ff0008"
 */
export const rgbToHex = (r: string | number, g: string | number, b: string | number) => {
  return [r, g, b]
    .map((color) => {
      let nr = color ? parseInt(String(color), 10) : 0;

      if (nr > 255) nr = 255;
      if (nr < 0) nr = 0;

      const hex = nr.toString(16);

      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");
};
